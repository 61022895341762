.image-uploader-trigger {
  width: 100%;
  background-color: var(--ion-color-light-tint);
  border: var(--ion-color-light-shade) 1px solid;
  border-radius: 4px;
  margin: 10px 0;
  overflow: hidden;
}

.image-uploader-button {
  height: auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: hidden;
}

.image-uploader-placeholder {
  display: block;
  color: var(--ion-color-medium-tint);
  padding: 0 20px;
}

.image-uploader-placeholder-text {
  display: block;
  padding-bottom: 18px;
  font-size: 12px;
}

.image-uploader-picture {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--ion-color-light-tint);
  margin: 0;
  overflow: hidden;
}

.image-uploader-photo-name {
  font-size: 14px;
  color: var(--ion-color-medium);
}

.image-uploader-action-group {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: var(--ion-color-light-shade) 1px solid;
}

.image-uploader-action {
  width: 50%;
  background-color: var(--ion-color-light-tint);
}

.image-uploader-action + .image-uploader-action {
  border-left: var(--ion-color-light-shade) 1px solid;
}

.image-uploader-action .button {
  margin: 0;
  align-items: center;
  padding: 4px;
}

.image-warning {
  position: relative;
  background-color: #ffca22;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: #000;
  font-size: 4px;
  cursor: pointer;
  border-radius: 4px;
}

.image-warning .offline-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-warning .offline-message-text {
  display: flex;
  align-items: center;
}

.image-warning .offline-message-detail {
  margin-top: 8px;
  font-size: 13px;
  color: #333;
}

.image-warning .chevron-icon {
  transition: transform 0.3s ease-in-out;
  font-size: 22px;
}
