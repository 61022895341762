.form-field-wrapper .label-stacked {
  font-size: 1rem !important;
}
.form-field-wrapper .label-stacked.sc-ion-label-md-h {
  transform: none;
  padding-top: 10px;
}

.form-field-wrapper .label-stacked.label-inline {
  display: inline-table;
}

.form-field-wrapper .label-stacked.label-small {
  font-size: 13px !important;
}

.form-field-hint {
  font-size: 14px;
  padding-bottom: 5px;
  align-self: flex-start;
}

.form-field-error {
  font-size: 12px;
  font-weight: 500;
  margin: 8px 0;
}

.hint-image.note {
  display: block;
}

.hint-image {
  display: contents;
}
.form-field-wrapper:has(.boolean-field) {
  margin-top: 0;
}

.form-field-wrapper .boolean-field {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.form-field-wrapper .boolean-field .label-stacked {
  display: inline-table;
}

.input-text input {
  padding-top: 2px !important;
  padding-bottom: 14px !important;
  line-height: 1.4 !important;
}
