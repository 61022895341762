.typography-h1 {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.typography-h2 {
  font-size: 24px;
}

.typography-h3 {
  font-size: 22px;
}

.typography-h4 {
  font-size: 20px;
}

.typography-h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.typography-h6 {
  font-size: 16px;
}

.typography-body1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.typography-body2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.typography-subtitle1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.typography-subtitle2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.typography-button {
  font-size: 14px;
}

.typography-caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.typography-overline {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.typography-breadcrumb {
  font-size: 16px;
}
