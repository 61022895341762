.export-header {
  position: fixed;
  /*  top: 116px; */
  border-bottom: #dedede 1px solid;
}

.export-header:after {
  background-image: none !important;
}

.export-chips {
  padding: 0 16px;
}

.export-row {
  display: flex;
  align-items: center;
}

.export-order {
  flex-grow: 1;
  text-align: right;
}

.export-content {
  --offset-top: -116px !important;
}
