.import-content-wrapper {
  padding: 15% 5%;
}

.import-content-wrapper .button {
  margin-bottom: 30px;
}

.import-button {
  height: auto;
}

.import-button-text {
  display: block;
  font-size: 20px !important;
}

.import-button-secondary-text {
  font-size: 16px;
}

.import-button-icon {
  position: absolute;
  left: 0;
}
