.filter-modal-item {
  position: relative;
  border-bottom: 0;
  border-color: transparent;
}

.filter-modal-item::after {
  content: '';
  border-bottom: 1px solid var(--ion-color-light-shade);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 50px;
  width: calc(100% - 50px);
  z-index: 1;
}

.filter-modal-footer-toolbar {
  --padding-start: 15px;
  --padding-end: 15px;
}
