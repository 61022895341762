.asset-map-filter {
  position: absolute;
  top: 16px;
  right: 16px;
  /* REVIEW this could be set via CSS variables to the button and would probably
   * give a better result, but we would need to re-think how it is reused */
  background: var(--ion-background-color);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
  /* NOTE make it round, just like the button */
  border-radius: 4;
}
