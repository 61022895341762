.login-expired-message {
  --min-height: 46px;
}

.login-expired-message-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 0;
}
