.sc-ion-popover-md-h {
  --max-height: 46% !important;
}

.popover-title {
  font-size: 14px;
  color: var(--ion-color-medium);
  padding-left: 14px;
  padding-right: 14px;
}
