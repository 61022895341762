.status-tag {
  height: 24px;
  margin: 4px 0px 0px 0px;
  padding: 4px 10px;
}

.status-tag-new {
  --background: #2dd36f1a;
  --color: #28ba62;
}

.status-tag-downloaded {
  --background: #6666661a;
  --color: #666666;
}

.status-tag-duplicated {
  --background: #3880ff1a;
  --color: #3880ff;
}

.status-tag-draft {
  --background: #ffc4091a;
  --color: #e0ac08;
}

.status-tag-finished {
  --background: #ff17871a;
  --color: #ff1787;
}

.status-tag-uploaded {
  --background: #b10cff1a;
  --color: #b10cff;
}
