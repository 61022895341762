.import-by-location-tabs {
  position: sticky;
  top: 0;
  isolation: isolate;
  z-index: 1;
  background-color: var(--ion-color-light-tint);
}

.import-by-location-view {
  display: none;
}

.import-by-location-view[data-selected='true'] {
  display: block;
}
