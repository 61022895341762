.app-footer .button-disabled {
  opacity: 0.25;
}

.app-footer .exit-button {
  transform: rotateY(180deg);
}

/* .app-footer .button {
  -webkit-margin-start: 8px !important;
  margin-inline-start: 8px !important;
  -webkit-margin-end: 8px !important;
  margin-inline-end: 8px !important;
}
 */
