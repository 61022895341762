.app-header-toolbar-ios {
  --min-height: 56px;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.app-header-title {
  font-size: 18px;
  padding-left: 0px;
  padding-right: 36px;
  /*   padding-right: 56px; */
  text-align: center;
}

.app-header-title-without-link {
  padding-right: 0px;
}

.app-header-title-ios {
  padding-left: 0px;
  padding-right: 0px;
}

.app-header-subtitle {
  font-size: 12px;
  /*   padding-left: 16px; */
  /* padding-right: 20px; */
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: auto;
  overflow: hidden;
}

.app-header-subtitle-ios {
  padding-left: 36px;
  padding-right: 36px;
}

/* NOTE: sticky search bar works when AppHeader is not fixed */
.app-searchBar-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.app-searchBar-backButton {
  margin-left: 4px;
}

.offline-message-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 0;
}

.moving-text {
  --animation-duration: 10s; /* Default animation duration, you can adjust this value as per your preference */
  animation: movingTextAnimation var(--animation-duration) linear infinite;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}
@keyframes movingTextAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    /* Use negative translation to move the text left */
    transform: translateX(calc(-100% + var(--animation-offset, 0px)));
  }
}
