.takeData-modal-header {
  font-size: 18px;
  padding-left: 0px;
  /* REVIEW this is the same as used in .app-header-title, but it is actually
  less than the left icon (usually a back button) takes */
  padding-right: 36px;
  text-align: center;

  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.takeData-modal-header-ios {
  padding-left: 0px;
  padding-right: 0px;
}

.takeData-results {
  display: flex;
  padding: 12px 12px 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.takeData-actions {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.takeData-applied-icon {
  position: absolute;
  top: 1px;
  right: -5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--ion-color-danger);
  isolation: isolate;
  z-index: 1;
}
