.advanced-asset-search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;

  /* NOTE added to adjust layout */
  padding: 16px 16px 20px 16px;
}

.advanced-asset-search-links {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.advanced-asset-search-link {
  flex: 1 0 0;
}

.advanced-asset-search-link-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
