.tree-navigation-trigger {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 100 !important;
  width: 36px;
  height: 36px;
}

.tree-navigation-trigger-ios {
  transform: translateY(-1px);
  z-index: 1000 !important;
  width: 40px;
  height: 40px;
  min-height: auto;
  border-radius: 100% !important;
}

.tree-navigation-trigger-icon {
  transform: rotate(90deg);
  font-size: 14px !important;
}
