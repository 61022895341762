.sketch-uploader-trigger {
  width: 100%;
  background-color: var(--ion-color-light-tint);
  border: var(--ion-color-light-shade) 1px solid;
  border-radius: 4px;
  margin: 10px 0;
  overflow: hidden;
}

.sketch-uploader-button {
  height: auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: hidden;
}
.sketch-uploader-placeholder {
  display: block;
  color: var(--ion-color-medium-tint);
  padding: 0 20px;
}

.sketch-uploader-placeholder-text {
  display: block;
  padding-bottom: 18px;
  font-size: 12px;
}

.sketch-uploader-picture {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--ion-color-light-tint);
  margin: 0;
  overflow: hidden;
}

.sketch-uploader-photo-name {
  font-size: 14px;
  color: var(--ion-color-medium);
}

.sketch-uploader-action-group {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: var(--ion-color-light-shade) 1px solid;
}

.sketch-uploader-action {
  width: 50%;
  background-color: var(--ion-color-light-tint);
}

.sketch-uploader-action + .sketch-uploader-action {
  border-left: var(--ion-color-light-shade) 1px solid;
}

.sketch-uploader-action .button {
  margin: 0;
  align-items: center;
}

.sketch-content-landscape {
  --offset-top: -72px !important;
  --offset-bottom: -100px !important;
  background-color: var(--ion-color-light-tint);
}

.sketch-content-portrait {
  --offset-bottom: -36px !important;
  background-color: var(--ion-color-light-tint);
}

.sketch-canvas-container {
  overflow: scroll;
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-light-tint);
  /*  */
  position: relative;
  flex-direction: column;
}
.sketch-canvas-container .landscape {
  width: 100%;
  /* height: auto; */
  height: 100%;
}
.sketch-canvas-container .portrait {
  width: auto;
  height: auto;
}

.sketch-canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.sketch-grid {
  position: relative;
}
.sketch-grid::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px;
  pointer-events: none;
}

.sketch-grid.hidden::after {
  display: none;
}

.sketch-buttons-top,
.sketch-buttons-down {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 100;
  position: fixed;
}

.portrait .sketch-buttons-top {
  flex-direction: column;
  /*   position: absolute;
  top: 0;
  bottom: 0;
  */
  top: 66px;
  left: 0;
  bottom: 60px;
}

.portrait .sketch-buttons-down {
  flex-direction: column;
  /*   position: absolute;
  bottom: 0;
  top: 0;
  */
  bottom: 60px;
  top: 60px;
  right: 0;
}

.landscape .sketch-buttons-top {
  flex-direction: row;
  /* position: absolute;
  top: 0;
  */
  top: 36px;
  left: 0;
  right: 0;
}

.landscape .sketch-buttons-down {
  flex-direction: row;
  /* position: absolute; 
  bottom: 0;
  */
  bottom: 42px;
  left: 0;
  right: 0;
}

.sketch-range-container {
  /*   position: absolute; */
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  z-index: 10;
}

.portrait .sketch-range-container {
  /* bottom: 5px; */
  bottom: 0;
  width: 40%;
  position: fixed;
}

.landscape .sketch-range-container {
  position: fixed;
  /* bottom: 80px; */
  bottom: 110px;
  left: 50%;
}

/* .sketch-footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
} */

.portrait .sketch-footer,
.portrait .sketch-footer-toolbar,
.portrait .sketch-footer-buttons {
  display: none;
}

.landscape .sketch-header-buttons {
  display: none;
}
