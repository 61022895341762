.order-modal-header {
  font-size: 18px;
  padding-left: 0px;
  /* REVIEW this is the same as used in .app-header-title, but it is actually
  less than the left icon (usually a back button) takes */
  padding-right: 36px;
  text-align: center;

  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.order-modal-header-ios {
  padding-left: 0px;
  padding-right: 0px;
}

.order-modal-fieldset {
  width: 100%;
  position: relative;
  min-height: 80px;

  & .sc-ion-label-md-h,
  & .item-lines-default {
    overflow: visible;
  }
}

.order-modal-fieldset::after {
  content: '';
  border-bottom: 1px solid var(--ion-color-light-shade);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 45px;
  width: calc(100% - 45px);
  z-index: 1;
}

.order-modal-checkboxItem {
  min-height: 45px;
  display: flex;
  align-items: center;
}

.order-modal-radioGroup {
  display: flex;
  width: 100%;
  padding-left: 16px;
}

.order-modal-radioItem {
  display: flex;
  justify-content: flex-start;
  height: 40px;
}

.order-modal-radioLabel {
  border-bottom: 0;
  text-align: left;
  line-height: 24px;
  margin-bottom: 12px;
}

.order-modal-radioButton {
  margin-right: 10px;
}

.order-small-button {
  margin: 0;
  font-size: 1.75em;
}
