.loading-overlay {
  position: absolute;
  inset: 0;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.78);

  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.loading-spinner-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.loading-spinner {
  height: 44px;
  width: 44px;
}
