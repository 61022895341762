.navFooter-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navFooter-button {
  height: auto;
  display: flex;
  flex: 1 1 0;

  color: #666666;
}

.navFooter-button.isActive {
  color: var(--ion-color-primary);
}

.navFooter-button-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 8px 4px 3px 4px;
}

.navFooter-icon {
  font-size: 24px;
}

.navFooter-label {
  font-size: 10px;
  text-transform: capitalize;
}
