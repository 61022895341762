.content-header {
  position: fixed;
  top: 56px;
}
.content-header-ios {
  /* top: 44px; */
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.content-header-toolbar {
  --background: var(--ion-color-light);
  --min-height: 38px;
}
.content-header-toolbar-ios {
  --min-height: 56px;
}

.content-header-title {
  --color: var(--ion-color-secondary);
  font-weight: 500;
  font-size: 1rem;
}
