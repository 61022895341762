.inspection-item {
  border-top: 1px solid var(--border-color, #d7d8da);
}

.inspection-item-label {
  /* NOTE override default `display` value for ion-label */
  display: flex !important;
  padding: 4px 16px 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
}

.inspection-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.inspection-item-secondary-text {
  color: var(--ion-color-step-600, #666666);
  display: flex;
  align-items: center;
  gap: 6px;
}

.inspection-item-secondary-text ion-icon {
  height: 20px;
  width: 20px;
}
