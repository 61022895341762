.cta-button-with-icon {
  height: auto;
  min-height: 56px;
  padding: 0;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  display: flex !important;
  flex: 1 !important;
  min-height: 56px !important;
  align-items: center !important;
}

.cta-button-label {
  white-space: normal;
}

.cta-label-with-icon {
  padding-left: 30px;
  padding-right: 30px;
}

.cta-button-text {
  display: block;
  font-size: 20px !important;
  text-align: center;
}

.cta-button-secondary-text {
  font-size: 16px;
}

.cta-button-icon {
  position: absolute;
  left: -10px;
}
