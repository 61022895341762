.tree-nav-link-icon {
  transform: rotate(90deg);
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin-right: 10px;
}

.tree-nav-form-icon {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin-right: 10px;
}

.tree-nav-item-note {
  font-size: 16px;
  align-self: center;
  padding-top: 10px !important;
}
