.home-wrapper {
  margin: 12% auto 0 auto;

  display: flex;
  flex-direction: column;
  height: calc(100svh - 12%);
}

.home-title {
  text-transform: uppercase;
  padding-bottom: 10px;
}

.home-wrapper .logo {
  max-height: 80%;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.home-wrapper img {
  display: inline-block;
  width: auto;
  max-height: 100%;
}

.home-action-buttons {
  display: flex;
  margin-top: 12px;
  padding: 16px;
  justify-content: space-evenly;
}

.home-wrapper .button-block {
  text-align: left;
}

.home-wrapper .button-block .button-icon-wrapper {
  display: inline-block;
  width: 20%;
}

.home-wrapper h1 {
  text-align: center;
  color: var(--ion-color-medium-shade);
  font-size: 20px;
  font-weight: 600;
}

.home-wrapper .cta-button-text {
  color: var(--color);
}
.temporary-action {
  text-align: center;
}

.home-fixed-content {
  flex-shrink: 0;
}

.home-list {
  flex-grow: 1;
  overflow-y: auto;

  padding: 0 16px;
}

.home-list-divider {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--ion-color-dark);
}

.home-empty {
  padding-top: 16px;
}
