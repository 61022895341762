.asset-card-item {
  --transition: background-color 1s ease;
}

.asset-card-item-details {
  font-size: 12px !important;
  display: flex;
  align-items: center;
}

.asset-card-item-details > span {
  margin: 0 4px;
}

.asset-card-download-button {
  min-height: 28px;
}

.asset-card-popover {
  --width: 300px;
  --max-height: 40%;
}
