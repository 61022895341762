.fab-container {
  /*   position: fixed; */
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.floating-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px; /* Ensures there is space above the options */
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.button-grid > * {
  margin-bottom: 10px;
}
