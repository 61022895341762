.asset-item,
.asset-item-options {
  --transition: background-color 0.5s ease;
}

.accordion-expanding .asset-item,
.accordion-expanded .asset-item,
/* NOTE make sure background (when sliding the item) looks exactly the same */
.accordion-expanding .asset-item-options,
.accordion-expanded .asset-item-options {
  --background: rgb(242, 242, 242); /* color='light' */
}

.asset-item-popover {
  --width: calc(100% - 32px);
  --max-width: 400px;
}
