.alert-button-primary,
.alert-button-primary.sc-ion-alert-md {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.alert-button-secondary,
.alert-button-secondary.sc-ion-alert-md {
  color: var(--ion-color-primary);
}

.alert-button-destructive,
.alert-button-destructive.sc-ion-alert-md {
  color: var(--ion-color-danger);
}
