.asset-nav-divider-ios {
  --background: var(--ion-background-color, #fff);
  min-height: 40px;
  border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  --color: var(--ion-color-step-400, #999999);
  --padding-start: 16px;
  --inner-padding-end: 16px;
}
