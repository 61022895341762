.add-link-field-item-icon {
  margin-right: 15px !important;
  align-self: start;
  padding-top: 2px;
  opacity: 0.8;
}

.add-link-field-item-label {
  font-size: 15px !important;
}
