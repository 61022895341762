.highlighted-item-group {
  margin-top: 30px;
  margin-bottom: 20px;
  border-top: var(--ion-color-light-shade) 1px solid;
  border-bottom: var(--ion-color-light-shade) 1px solid;
}

.danger-item-group {
  border-top: var(--ion-color-danger-tint) 1px solid;
  border-bottom: var(--ion-color-danger-tint) 1px solid;
}

.settings-user-block {
  padding-top: 32px;
}

.settings-logo {
  max-height: 60%;
  width: 100%;
  display: block;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 14px;
}

.settings-logo img {
  display: inline-block;
  width: auto;
  max-height: 100%;
  height: 48px;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  overflow: hidden;
}

.settings-username {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: var(--ion-color-dark-tint);
}

.settings-logout-button {
  margin-bottom: 16px;
}
