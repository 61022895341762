.login-wrapper {
  margin: 20% auto;
}

.login-wrapper .logo {
  max-height: 60%;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  overflow: hidden;
}

.login-wrapper img {
  display: inline-block;
  width: auto;
  max-height: 100%;
}

.login-wrapper h1 {
  text-align: center;
  color: var(--ion-color-medium-shade);
  font-size: 20px;
  font-weight: 600;
  font-size: inherit;
  margin-top: 10px;
}

.login-wrapper .label-stacked {
  font-size: 14px !important;
}

.login-wrapper .button {
  margin-top: 16px;
  min-height: 56px;
  text-transform: uppercase;
  --border-radius: 4px;
}
