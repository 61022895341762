.import-by-attributes-heading {
  padding: 24px 0px 18px 16px;
}

.import-by-attributes-filter-button {
  position: absolute;
  right: 24px;
  top: 21px;
}

.import-by-attributes-filter-icon {
  width: 28px;
  height: 28px;
}

.import-by-attributes-form {
  padding: 0px 16px;
}

.import-by-attributes-footer-toolbar {
  --padding-start: 15px;
  --padding-end: 15px;
}
