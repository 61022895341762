.round-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.round-button {
  margin: 0;
  height: var(--btn-size);
  width: var(--btn-size);
  border-radius: 50%;
}

.round-button.is-large {
  --btn-size: 68px;
  --btn-font-size: 24px;
}

.round-button.is-medium {
  --btn-size: 56px;
  --btn-font-size: 20px;
}

.round-button-icon {
  position: absolute;
  font-size: var(--btn-font-size);
}

.round-button-label {
  font-size: 12px;
  margin-top: 10px;
  letter-spacing: 0.4px;
}
