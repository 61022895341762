.send-data-popover .popover-content {
  padding: 18px;
}

.send-data-title {
  font-size: 14px;
  color: var(--ion-color-medium);
  text-align: center;
  text-transform: uppercase;
}

.send-data-progress-bar {
  margin-top: 32px;
  margin-bottom: 32px;
}

.send-data-text {
  font-size: 16px;
  padding-right: 30px;
  padding-left: 30px;
  color: var(--ion-color-dark-tint);
}

.send-data-figure {
  font-weight: 500;
  color: var(--ion-color-dark);
}

.send-data-action {
  margin-top: 40px;
}
