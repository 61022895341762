.select-field {
  --input-padding-top: 2px;
  --input-padding-bottom: 14px;
}
.select-icon-container {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 4px;
}

.select-icon {
  font-size: 16px;
  color: #757575;
  opacity: 0.8;
  padding: 12px;
}

.select-placeholder {
  color: var(--ion-color-medium-tint);
  width: 100%;
  padding-top: var(--input-padding-top);
  padding-bottom: var(--input-padding-bottom);
}

.select-values {
  width: 100%;
  padding-top: var(--input-padding-top);
  padding-bottom: var(--input-padding-bottom);
  font-size: 16px;
}

.select-chip {
  height: auto;
}

.select-footer {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

.select-toolbar {
  display: flex;
  justify-content: space-between;
}

.select-button-group {
  display: flex;
  justify-content: flex-end;
}

.select-button-group .select-button-clear {
  margin-right: auto;
  color: var(--ion-color-danger);
  --padding-end: 0;
  --padding-start: 0;
}

.select-button-group .select-button-confirm {
  margin-left: 8px;
}

.select-button-clear .button-text {
  margin-left: 8px;
  margin-top: 2px;
}

.select-footer .button-text {
  font-size: '13px';
  letter-spacing: 0.78px;
  text-transform: uppercase;
}
