.empty-item {
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
}

.empty-item .label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-item .icon {
  color: var(--ion-color-medium);
  margin-bottom: 18px;
}

.empty-item .text {
  color: #666666;
  margin-bottom: 4px;
}
.empty-item .secondary-text {
  color: var(--ion-color-medium);
}
